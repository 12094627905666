var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "vs-sidebar",
    {
      staticClass: "add-new-data-sidebar items-no-padding",
      attrs: {
        "click-not-close": "",
        "position-right": "",
        parent: "body",
        "default-index": "1",
        color: "primary",
        spacer: ""
      },
      model: {
        value: _vm.isSidebarActiveLocal,
        callback: function($$v) {
          _vm.isSidebarActiveLocal = $$v
        },
        expression: "isSidebarActiveLocal"
      }
    },
    [
      _c(
        "div",
        { staticClass: "mt-6 flex items-center justify-between px-6" },
        [
          _c("h4", [_vm._v("AÑADIR CRÉDITO")]),
          _c("feather-icon", {
            staticClass: "cursor-pointer",
            attrs: { icon: "XIcon" },
            on: {
              click: function($event) {
                $event.stopPropagation()
                _vm.isSidebarActiveLocal = false
              }
            }
          })
        ],
        1
      ),
      _c("vs-divider", { staticClass: "mb-0" }),
      _c(
        "VuePerfectScrollbar",
        {
          staticClass: "scroll-area--data-list-add-new pt-4 pb-6",
          attrs: { settings: _vm.settings }
        },
        [
          _c(
            "div",
            { staticClass: "p-6" },
            [
              _c("vs-input", {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: {
                      required: true,
                      regex: /^-?[0-9]*((\.|\,)[0-9]{0,2})?$/
                    },
                    expression:
                      "{\n          required: true,\n          regex: /^-?[0-9]*((\\.|\\,)[0-9]{0,2})?$/\n        }"
                  }
                ],
                staticClass: "mt-5 w-full form-item",
                attrs: {
                  placeholder: "0.00",
                  label: "Crédito a añadir",
                  name: "add-credit"
                },
                on: { keyup: _vm.validateCredit },
                model: {
                  value: _vm.creditToAdd,
                  callback: function($$v) {
                    _vm.creditToAdd = $$v
                  },
                  expression: "creditToAdd"
                }
              }),
              _c("span", { staticClass: "text-danger" }, [
                _vm._v(_vm._s(_vm.errorCreditInput))
              ]),
              _c(
                "label",
                {
                  staticClass: "lbl-credit-type form-item",
                  attrs: { for: "credit-type" }
                },
                [_vm._v("Tipo de crédito")]
              ),
              _c("b-form-select", {
                attrs: { id: "credit-type", options: _vm.optionsCredit },
                model: {
                  value: _vm.creditType,
                  callback: function($$v) {
                    _vm.creditType = $$v
                  },
                  expression: "creditType"
                }
              }),
              _c("vs-input", {
                staticClass: "form-item",
                attrs: { placeholder: "Descripción", label: "Descripción" },
                model: {
                  value: _vm.creditDescription,
                  callback: function($$v) {
                    _vm.creditDescription = $$v
                  },
                  expression: "creditDescription"
                }
              })
            ],
            1
          )
        ]
      ),
      _c(
        "div",
        {
          staticClass: "flex flex-wrap items-center justify-center p-6",
          attrs: { slot: "footer" },
          slot: "footer"
        },
        [
          _c(
            "vs-button",
            {
              staticClass: "mr-6",
              attrs: { disabled: _vm.validCredit },
              on: { click: _vm.addCreditBtnClk }
            },
            [_vm._v("Añadir crédito")]
          ),
          _c(
            "vs-button",
            {
              attrs: { type: "border", color: "danger" },
              on: {
                click: function($event) {
                  _vm.isSidebarActiveLocal = false
                }
              }
            },
            [_vm._v("Cancelar")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }