<template>
  <vs-sidebar click-not-close position-right parent="body" default-index="1" color="primary" class="add-new-data-sidebar items-no-padding" spacer v-model="isSidebarActiveLocal">
    <div class="mt-6 flex items-center justify-between px-6">
      <h4>AÑADIR CRÉDITO</h4>
      <feather-icon icon="XIcon" @click.stop="isSidebarActiveLocal = false" class="cursor-pointer"></feather-icon>
    </div>
    <vs-divider class="mb-0"></vs-divider>
    <VuePerfectScrollbar class="scroll-area--data-list-add-new pt-4 pb-6" :settings="settings">
      <div class="p-6">
        <!-- NAME -->
        <vs-input v-model="creditToAdd" placeholder="0.00" label="Crédito a añadir" class="mt-5 w-full form-item" name="add-credit" @keyup="validateCredit" v-validate="{
            required: true,
            regex: /^-?[0-9]*((\.|\,)[0-9]{0,2})?$/
          }" />
        <span class="text-danger">{{ errorCreditInput }}</span>
        <label class="lbl-credit-type form-item" for="credit-type">Tipo de crédito</label>
        <b-form-select id="credit-type" v-model="creditType" :options="optionsCredit"></b-form-select>
        <vs-input class="form-item" v-model="creditDescription" placeholder="Descripción" label="Descripción" />
      </div>
    </VuePerfectScrollbar>

    <div class="flex flex-wrap items-center justify-center p-6" slot="footer">
      <vs-button class="mr-6" @click="addCreditBtnClk" :disabled="validCredit">Añadir crédito</vs-button>
      <vs-button type="border" color="danger" @click="isSidebarActiveLocal = false">Cancelar</vs-button>
    </div>
  </vs-sidebar>
</template>

<script>
import VuePerfectScrollbar from "vue-perfect-scrollbar"
import Amplify from "aws-amplify"

export default {
  props: {
    isSidebarActive: {
      type: Boolean,
      required: true
    },
    userSelected: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      creditType: 'free_credit',
      optionsCredit: [
        { value: 'free_credit', text: 'Crédito regalado' },
        { value: 'credit_card', text: 'Crédito de tarjeta' },
        { value: 'bank_transfer', text: 'Transferencia bancaria' }
      ],
      creditDescription: 'Crédito regalado',
      creditToAdd: '',
      validCredit: true,

      settings: {
        // perfectscrollbar settings
        maxScrollbarLength: 60,
        wheelSpeed: 0.6
      },
      errorCreditInput: ''
    }
  },
  computed: {
    isSidebarActiveLocal: {
      get() {
        return this.isSidebarActive
      },
      set(val) {
        if (!val) {
          this.$emit("closeSidebar")
          this.initValues()
        }
      }
    },
    userSelectedLocal: {
      get() {
        return this.userSelected
      }
    }
  },
  methods: {
    initValues() {
      this.creditToAdd = ''
    },
    validateCredit() {
      this.$validator.validateAll().then(result => {
        if (result) {
          this.errorCreditInput = ''
          this.validCredit = false
          return true
        } else {
          this.errorCreditInput = 'Porfavor comprueba el formato'
          this.validCredit = true
          return false
        }
      })
    },
    addCreditBtnClk() {
      this.$vs.loading()
      // INI Adding User Account
      Amplify.Auth.currentSession().then(apiSession => {
        // INI Get user
        const paramsActions = {
          action: 'addCredit',
          userId: this.userSelectedLocal,
          value: this.creditToAdd.replace(',', '.'),
          payType: this.creditType,
          description: this.creditDescription
        }
        this.$axios.put(`/admin/actions/${paramsActions.action}`, paramsActions, {
          headers: { 'Authorization': 'Bearer ' + apiSession.idToken.jwtToken }
        }).then(response => {
          if (response.status === 200) {
            this.isSidebarActiveLocal = false
            this.validCredit = true
            this.creditToAdd = ''
            this.$vs.loading.close()
          }
        }).catch(() => {
          this.$vs.loading.close()
        })
        // FIN Get user
      }).catch(() => {
        this.$vs.loading.close()
      })
      // FIN Adding User Account
    }
  },
  components: {
    VuePerfectScrollbar
  }
}
</script>

<style lang="scss" scoped>
.lbl-credit-type {
  padding-left: 5px;
  font-size: 0.85rem;
  color: rgba(0, 0, 0, 0.7);
}
.form-item {
  margin-top: 15px;
}

.add-new-data-sidebar {
  /deep/ .vs-sidebar--background {
    z-index: 52010;
  }

  /deep/ .vs-sidebar {
    z-index: 52010;
    width: 400px;
    max-width: 90vw;

    .img-upload {
      margin-top: 2rem;

      .con-img-upload {
        padding: 0;
      }

      .con-input-upload {
        width: 100%;
        margin: 0;
      }
    }
  }
}

.scroll-area--data-list-add-new {
  height: calc(100% - 4.3rem);
}
</style>