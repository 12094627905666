var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-container",
    { attrs: { fluid: "" } },
    [
      _c("add-credit-sidebar", {
        attrs: {
          isSidebarActive: _vm.addCreditSidebar,
          userSelected: _vm.userSelected
        },
        on: { closeSidebar: _vm.closeSidebarCreEvt }
      }),
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { attrs: { sm: "5", md: "6" } },
            [
              _c(
                "b-form-group",
                {
                  staticClass: "mb-0",
                  attrs: {
                    label: "Filter",
                    "label-cols-sm": "3",
                    "label-align-sm": "right",
                    "label-size": "sm",
                    "label-for": "filterInput"
                  }
                },
                [
                  _c(
                    "b-input-group",
                    { attrs: { size: "sm" } },
                    [
                      _c("b-form-input", {
                        attrs: {
                          type: "search",
                          id: "filterInput",
                          placeholder: "Escribe para buscar"
                        },
                        model: {
                          value: _vm.filter,
                          callback: function($$v) {
                            _vm.filter = $$v
                          },
                          expression: "filter"
                        }
                      }),
                      _c(
                        "b-input-group-append",
                        [
                          _c(
                            "b-button",
                            {
                              attrs: { disabled: !_vm.filter },
                              on: {
                                click: function($event) {
                                  _vm.filter = ""
                                }
                              }
                            },
                            [_vm._v("Borrar")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { sm: "5", md: "6" } },
            [
              _c(
                "b-form-group",
                {
                  staticClass: "mb-0",
                  attrs: {
                    label: "Por página",
                    "label-cols-sm": "6",
                    "label-cols-md": "4",
                    "label-cols-lg": "3",
                    "label-align-sm": "right",
                    "label-size": "sm",
                    "label-for": "perPageSelect"
                  }
                },
                [
                  _c("b-form-select", {
                    attrs: {
                      id: "perPageSelect",
                      size: "sm",
                      options: _vm.pageOptions
                    },
                    model: {
                      value: _vm.perPage,
                      callback: function($$v) {
                        _vm.perPage = $$v
                      },
                      expression: "perPage"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { attrs: { sm: "5", md: "6" } },
            [
              _c(
                "vs-dropdown",
                {
                  staticClass: "cursor-pointer mr-4 mb-4",
                  attrs: { "vs-trigger-click": "" }
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "p-4 shadow-drop rounded-lg d-theme-dark-bg cursor-pointer flex items-center justify-center text-lg font-medium w-32"
                    },
                    [
                      _c("span", { staticClass: "mr-2" }, [_vm._v("Acciones")]),
                      _c("feather-icon", {
                        attrs: {
                          icon: "ChevronDownIcon",
                          svgClasses: "h-4 w-4"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "vs-dropdown-menu",
                    [
                      _c(
                        "vs-dropdown-item",
                        {
                          attrs: { disabled: _vm.rowSel === false },
                          on: {
                            click: function($event) {
                              _vm.addCreditSidebar = true
                            }
                          }
                        },
                        [_c("span", [_vm._v("Añadir crédito")])]
                      ),
                      _c(
                        "vs-dropdown-item",
                        {
                          attrs: { disabled: _vm.rowSel === false },
                          on: { click: _vm.menuDisableClk }
                        },
                        [_c("span", [_vm._v("Deshabilitar")])]
                      ),
                      _c(
                        "vs-dropdown-item",
                        {
                          attrs: { disabled: _vm.rowSel === false },
                          on: { click: _vm.menuEnableClk }
                        },
                        [_c("span", [_vm._v("Habilitar")])]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "b-col",
            { staticClass: "pagination-table", attrs: { sm: "5", md: "6" } },
            [
              _c("b-pagination", {
                staticClass: "my-0",
                attrs: {
                  "total-rows": _vm.totalRows,
                  "per-page": _vm.perPage,
                  align: "fill",
                  size: "sm"
                },
                model: {
                  value: _vm.currentPage,
                  callback: function($$v) {
                    _vm.currentPage = $$v
                  },
                  expression: "currentPage"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-container",
        { staticClass: "text-light text-center", attrs: { fluid: "" } },
        [
          _c(
            "b-row",
            [
              _c("b-col", {
                staticClass: "mr-auto p-3",
                attrs: { cols: "auto" }
              }),
              _c(
                "b-col",
                { staticClass: "p-3", attrs: { cols: "auto" } },
                [
                  _c("b-button", { on: { click: _vm.updateTable } }, [
                    _vm._v("Actualizar tabla")
                  ])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("b-table", {
        staticClass: "table-users",
        attrs: {
          selectable: "",
          "select-mode": "single",
          bordered: "",
          hover: "",
          "show-empty": "",
          small: "",
          stacked: "md",
          items: _vm.items,
          fields: _vm.fields,
          "current-page": _vm.currentPage,
          "per-page": _vm.perPage,
          filter: _vm.filter,
          filterIncludedFields: _vm.filterOn,
          "sort-by": _vm.sortBy,
          "sort-desc": _vm.sortDesc,
          "sort-direction": _vm.sortDirection
        },
        on: {
          "update:sortBy": function($event) {
            _vm.sortBy = $event
          },
          "update:sort-by": function($event) {
            _vm.sortBy = $event
          },
          "update:sortDesc": function($event) {
            _vm.sortDesc = $event
          },
          "update:sort-desc": function($event) {
            _vm.sortDesc = $event
          },
          filtered: _vm.onFiltered,
          "row-selected": _vm.onRowSelected
        },
        scopedSlots: _vm._u([
          {
            key: "cell(actions)",
            fn: function(row) {
              return [
                _c(
                  "b-button",
                  {
                    attrs: { size: "sm", variant: "outline-primary" },
                    on: { click: row.toggleDetails }
                  },
                  [
                    _vm._v(
                      "\n        " +
                        _vm._s(row.detailsShowing ? "Ocultar" : "Mostrar") +
                        " Detalles\n      "
                    )
                  ]
                ),
                _c(
                  "b-button",
                  {
                    staticClass: "btn-actions",
                    attrs: { size: "sm", variant: "outline-info" },
                    on: {
                      click: function($event) {
                        return _vm.useUser(row.item)
                      }
                    }
                  },
                  [_vm._v("\n        Usar\n      ")]
                )
              ]
            }
          },
          {
            key: "row-details",
            fn: function(row) {
              return [
                _c("b-card", [
                  _c("ul", [
                    _c("li", [
                      _c("b", [_vm._v("Fecha de alta:")]),
                      _vm._v(" " + _vm._s(row.item.mainAccount))
                    ]),
                    _c("li", [
                      _c("b", [_vm._v("Cuentas:")]),
                      _vm._v(" " + _vm._s(row.item.nAccounts))
                    ]),
                    _c("li", [
                      _c("b", [_vm._v("Campañas:")]),
                      _vm._v(" " + _vm._s(row.item.nCampaigns))
                    ])
                  ])
                ])
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }