<template>
  <b-container fluid>
    <add-credit-sidebar :isSidebarActive="addCreditSidebar" @closeSidebar="closeSidebarCreEvt" :userSelected="userSelected" />
    <!-- User Interface controls -->
    <b-row>
      <b-col sm="5" md="6">
        <b-form-group label="Filter" label-cols-sm="3" label-align-sm="right" label-size="sm" label-for="filterInput" class="mb-0">
          <b-input-group size="sm">
            <b-form-input v-model="filter" type="search" id="filterInput" placeholder="Escribe para buscar"></b-form-input>
            <b-input-group-append>
              <b-button :disabled="!filter" @click="filter = ''">Borrar</b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>

      <b-col sm="5" md="6">
        <b-form-group label="Por página" label-cols-sm="6" label-cols-md="4" label-cols-lg="3" label-align-sm="right" label-size="sm" label-for="perPageSelect" class="mb-0">
          <b-form-select v-model="perPage" id="perPageSelect" size="sm" :options="pageOptions"></b-form-select>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col sm="5" md="6">
        <vs-dropdown vs-trigger-click class="cursor-pointer mr-4 mb-4">
          <div class="p-4 shadow-drop rounded-lg d-theme-dark-bg cursor-pointer flex items-center justify-center text-lg font-medium w-32">
            <span class="mr-2">Acciones</span>
            <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
          </div>
          <vs-dropdown-menu>
            <vs-dropdown-item @click="addCreditSidebar = true" :disabled="rowSel === false">
              <span>Añadir crédito</span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="menuDisableClk" :disabled="rowSel === false">
              <span>Deshabilitar</span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="menuEnableClk" :disabled="rowSel === false">
              <span>Habilitar</span>
            </vs-dropdown-item>
          </vs-dropdown-menu>
        </vs-dropdown>
      </b-col>
      <b-col sm="5" md="6" class="pagination-table">
        <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="fill" size="sm" class="my-0"></b-pagination>
      </b-col>
    </b-row>

    <b-container fluid class="text-light text-center">
      <b-row>
        <b-col cols="auto" class="mr-auto p-3"></b-col>
        <b-col cols="auto" class="p-3">
          <b-button @click="updateTable">Actualizar tabla</b-button>
        </b-col>
      </b-row>
    </b-container>

    <!-- Main table element -->
    <b-table selectable select-mode="single" bordered hover class="table-users" show-empty small stacked="md" :items="items" :fields="fields" :current-page="currentPage" :per-page="perPage" :filter="filter" :filterIncludedFields="filterOn" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" @filtered="onFiltered" @row-selected="onRowSelected">
      <template v-slot:cell(actions)="row">
        <b-button size="sm" @click="row.toggleDetails" variant="outline-primary">
          {{ row.detailsShowing ? 'Ocultar' : 'Mostrar' }} Detalles
        </b-button>
        <b-button size="sm" @click="useUser(row.item)" class="btn-actions" variant="outline-info">
          Usar
        </b-button>
      </template>

      <template v-slot:row-details="row">
        <b-card>
          <ul>
            <li><b>Fecha de alta:</b> {{row.item.mainAccount}}</li>
            <li><b>Cuentas:</b> {{row.item.nAccounts}}</li>
            <li><b>Campañas:</b> {{row.item.nCampaigns}}</li>
          </ul>
        </b-card>
      </template>
    </b-table>
  </b-container>
</template>

<script>
import AddCreditSidebar from '@/components/AddCreditSidebar'
import Amplify from 'aws-amplify'
import { EventBus } from '@/event-bus'

export default {
  data() {
    return {
      addCreditSidebar: false,
      userSelected: '',
      rowSel: false,
      items: [],
      firstElement: null,
      fields: [
        { key: 'name', label: 'Nombre', sortable: true, sortDirection: 'desc' },
        { key: 'email', label: 'Email', sortable: true, sortDirection: 'desc' },
        { key: 'mainAccountName', label: 'Cuenta' },
        { key: 'credit', label: 'Crédito', sortable: true, sortDirection: 'desc' },
        { key: 'amountGA', label: 'Gastado Ads', sortable: true, sortDirection: 'desc' },
        { key: 'entryDate', label: 'Alta', sortable: true, sortDirection: 'desc' },
        { key: 'lastAccess', label: 'Ultima Conexión', sortable: true, sortDirection: 'desc' },
        {
          key: 'isActive',
          label: 'Activo',
          formatter: (value) => {
            return value ? 'Si' : 'No'
          },
          sortable: true,
          sortByFormatted: true,
          filterByFormatted: true
        },
        { key: 'actions', label: 'Acciones' },
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 25,
      pageOptions: [5, 10, 15, 25],
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: []
    }
  },
  mounted() {
    this.$vs.loading()
    this.items = []
    this.getUsers()
  },
  methods: {
    closeSidebarCreEvt() {
      this.addCreditSidebar = false
      this.updateTable()
    },
    updateTable() {
      this.items = []
      this.$vs.loading()
      this.getUsers()
    },
    menuDisableClk() {
      const dataAction = {
        action: 'enableUser',
        value: false
      }
      this.sendAction(dataAction)
    },
    menuEnableClk() {
      const dataAction = {
        action: 'enableUser',
        value: true
      }
      this.sendAction(dataAction)
    },
    onRowSelected(items) {
      if (items.length > 0) {
        this.rowSel = true
        this.userSelected = items[0].userId
      } else {
        this.rowSel = false
        this.userSelected = ''
      }
    },
    useUser(dataRow) {
      this.$store.dispatch('emptyStats', false)
      EventBus.$emit('use-admin')
      this.$store.dispatch('updateMainAccount', {
        id: dataRow.mainAccount,
        name: dataRow.mainAccountName
      })
      this.$store.dispatch('updateUserId', {
        userId: dataRow.userId
      })
      this.$store.dispatch('setUserData', false)
      setTimeout(() => {
        this.$router.push('/')
      }, 1000)
      //TODO: Eliminar con destroy la tabla 
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    getUsers() {
      this.$vs.loading()
      Amplify.Auth.currentSession().then(apiSession => {
        let paramsAdmin = {
          numItems: 1000,
          firstItem: this.firstElement,
        }
        this.$axios.post(`/admin`, paramsAdmin, {
          headers: {
            Authorization: "Bearer " + apiSession.idToken.jwtToken
          }
        })
          .then(response => {
            const results = response.data
            const dataitems = this.composeUsersData(results.items)
            this.items = this.items.concat(dataitems)
            this.firstElement = results.lastIndex
            this.$vs.loading.close()
            if (this.firstElement != null) {
              this.getUsers()
            }
          })
        return null
      })
    },
    composeUsersData(dataUsers) {
      //TODO: Hacer llamada con lastAccess,entryDate y amountGA
      let adminUsers = []
      for (const user of dataUsers) {
        let userData = {
          name: user['use.name'],
          email: user['use.email'],
          isActive: user['use.active'],
          credit: user['use.credit'] + ' ' + user['use.currencySymbol'],
          initialCredit: user['use.initialCredit'],
          nAccounts: user['use.nAccounts'],
          nCampaigns: user['use.nCampaigns'],
          mainAccount: user['mainAccount'],
          mainAccountName: user['mainAccountName'],
          userId: user['userId'],
          lastAccess: '17/11/2020 15:00:42',
          entryDate: '22/05/2019 14:24:54',
          amountGA: '4500$'
        }
        if (user['use.credit'] < 0) {
          userData._rowVariant = 'warning'
        } else if (user['use.credit'] > 0) {
          userData._rowVariant = 'success'
        }
        adminUsers.push(userData)
      }
      this.totalRows = this.totalRows + dataUsers.length
      return adminUsers
    },
    sendAction(dataAction) {
      this.$vs.loading()
      Amplify.Auth.currentSession().then(apiSession => {
        // INI Get user
        const paramsActions = {
          userId: this.userSelected,
          value: dataAction.value
        }
        this.$axios.put(`/admin/actions/${dataAction.action}`, paramsActions, {
          headers: { 'Authorization': 'Bearer ' + apiSession.idToken.jwtToken }
        }).then(() => {
          this.getUsers()
        })
        // FIN Get user
      })
    }
  },
  components: {
    AddCreditSidebar,
  },
}
</script>
<style scoped>
.btn-actions {
  margin-left: 10px;
}
.table-users {
  margin-top: 20px;
}
.pagination-table {
  margin-top: 10px;
  margin-bottom: 10px;
}
</style>